
import { defineComponent } from "vue";
import { Input, Button, message } from "ant-design-vue";
import DragCard from "@/components/channel/DragCard.vue";
import DragCard2 from "@/components/channel/DragCard2.vue";
import DragCard3 from "@/components/channel/DragCard3.vue";
import DragCard4 from "@/components/channel/DragCard4.vue";
import {} from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    Image,
    Button,
    DragCard,
    DragCard2,
    DragCard3,
    DragCard4,
  },
  data() {
    return {};
  },
  methods: {
    myEvent() {
      (this as any).$refs.items1.getList();
    },
  },
  watch: {},
  mounted() {
    (this as any).$store.commit("changeChannelEdit", false);
    (this as any).$store.commit("changeBreadcrumb", ["主数据中心", "渠道管理"]);
  },
  created() {
    document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    };
  },
  
});
