import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ce11caf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Channel" }
const _hoisted_2 = { class: "contentBox" }
const _hoisted_3 = { class: "cardBox" }
const _hoisted_4 = { class: "last" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DragCard = _resolveComponent("DragCard")!
  const _component_DragCard2 = _resolveComponent("DragCard2")!
  const _component_DragCard3 = _resolveComponent("DragCard3")!
  const _component_DragCard4 = _resolveComponent("DragCard4")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createVNode(_component_DragCard, { ref: "items1" }, null, 512)
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_DragCard2, {
            ref: "items2",
            onMyEvent: _ctx.myEvent
          }, null, 8, ["onMyEvent"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_DragCard3, { ref: "items3" }, null, 512)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_DragCard4, { ref: "items4" }, null, 512)
        ])
      ])
    ])
  ]))
}