
import { defineComponent } from "vue";
import { Modal } from "ant-design-vue";
export default defineComponent({
  components: {
    Modal,
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      determine: false,
      code: "",
    };
  },
  methods: {
    showModal(categoryCode) {
      this.code = categoryCode;
      this.visible = true;
    },
    handleOk() {
      this.confirmLoading = true;
      this.determine = true;
      this.$emit("todoDel", this.code);
    },
  },
});
