
import { defineComponent, createVNode } from "vue";
import { Input, Button, message, Card, Modal } from "ant-design-vue";
import {
  DragOutlined,
  EditOutlined,
  DeleteOutlined,
  RightOutlined,
  CheckOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import draggable from "vuedraggable";
import {
  // sortCategory,
  channelAdd,
  channelUpdate,
  channelDelete,
  channelOrder,
} from "@/api/terminalList.js";
import ModalDel from "./Modal.vue";

export default defineComponent({
  components: {
    Button,
    Input,
    message,
    Card,
    draggable,
    DragOutlined,
    EditOutlined,
    DeleteOutlined,
    RightOutlined,
    ModalDel,
    CheckOutlined,
    PlusOutlined,
  },
  data() {
    return {
      height: "500px",
      editCategoryCode: -1,
      drag: false,
      newCategory: "",
      keepCategoryName: "",
      addNewCategory: false,
      activeCode: "",
      loading: false,
      contentHeight: "500px",
      channelPromiseList: [],
      draggabled: false,
    };
  },
  computed: {},
  methods: {
    //点击新增渠道
    addnew() {
      (this as any).$store.commit("changeChannelEdit", true);
      this.addNewCategory = true;
      setTimeout(() => {
        let input = document.querySelector("input");
        if (input) {
          input.focus();
        }
      }, 0);
    },
    //失去焦点 新增渠道
    blurAddNewCategory() {
      if (this.newCategory.trim().length == 0) {
        this.addNewCategory = false;
        (this as any).$store.commit("changeChannelEdit", false);
        return;
      }
      if (this.checkCategory(this.newCategory, "addNew")) {
        return;
      }
      let sort = 1000 - (this as any).$store.state.channelCode1.length;
      channelAdd({
        channelParCode: (this as any).$store.state.channel1,
        name: this.newCategory,
        level: 2,
      })
        .then((res) => {
          if (res.data.success) {
            this.$emit("myEvent"); //通知第一栏重新获取数据
            message.success("新增成功");
            this.newCategory = "";
            (this as any).$store.commit(
              "changeChannelCode1",
              (this as any).$store.state.channel1
            );
            this.addNewCategory = false;
          } else {
            this.addNewCategory = false;
          }
          (this as any).$store.commit("changeChannelEdit", false);
        })
        .catch(() => {
          this.addNewCategory = false;
          (this as any).$store.commit("changeCategoryEdit", false);
        });
    },
    //获取afterSort
    getAfterSort(array) {
      array.forEach((item, index) => {
        item.afterSort = 1000 - index;
        item.beforeSort = 1000 - index;
      });
    },
    //校验
    checkCategory(str, type) {
      let flag = false;
      str = str.trim();
       //不可仅输入数字
      if (typeof Number(str) == "number"&& String(Number(str))!='NaN') {
        message.warning("渠道名称不可仅输入数字");
        flag = true;
      }
      let length1 = (this as any).$store.state.channelCode1.length;
      let item2 = (this as any).$store.state.channelCode1.map((ele: any) => {
        return ele.channelName;
      });
      //console.log((this as any).$store.state.channelCode1);

      let length2 = [...new Set(item2)].length;
      // console.log(length1 ,item2);

      if (length1 !== length2) {
        message.warning("当前渠道名称已存在,不可重复使用");
        flag = true;
      }
      //新增时通过 只有一个重复判断出重复 编辑重复就是两个重复
      (this as any).$store.state.channelCode1.forEach((ele: any, i) => {
        if (ele.channelName === str && type == "addNew") {
          message.warning("当前渠道名称已存在,不可重复使用");
          flag = true;
        }
      });
      if (str.length > 50) {
        message.warning("最多不能超过50个字符");
        flag = true;
      }
      if (str.length == 0) {
        message.warning("请输入渠道名称");
        flag = true;
      }

      var reg = new RegExp("^[A-Za-z0-9\u4e00-\u9fa5]+$");
      if (!reg.test(str)) {
        message.warning("仅支持汉字、字母、数字");
        flag = true;
      }

      if (flag) {
        let input = document.querySelector("input");
        if (input) {
          input.focus();
        }
      }
      return flag;
    },
    //编辑
    edit(editCategoryCode, categoryName) {
      this.draggabled = true;
      this.keepCategoryName = categoryName;
      this.editCategoryCode = editCategoryCode;
      setTimeout(() => {
        let input = document.querySelector("input");
        if (input) {
          input.focus();
        }
      }, 0);
    },
    editCategroy(ele) {
      this.draggabled = false;
      if (this.editCategoryCode == -1) {
        return;
      }
      if (this.$route.path != "/masterData/channel") {
        return;
      }

      if (ele.channelName == "") {
        (this as any).$store.commit(
          "changeChannelCode1",
          (this as any).$store.state.channel1
        );
        this.editCategoryCode = -1;
        return;
      }
      if (this.keepCategoryName != ele.channelName) {
        if (this.checkCategory(ele.channelName, "edit")) {
          return;
        }
        channelUpdate({
          code: ele.channelCode,
          name: ele.channelName,
          channelParCode: (this as any).$store.state.channel1,
          level: 2,
        }).then((res) => {
          if (res.data.success) {
            message.success("编辑渠道成功");
            (this as any).$store.commit(
              "changeChannelCode1",
              (this as any).$store.state.channel1
            );
            this.editCategoryCode = -1;
          }
          // else {
          //   message.error(res.data.errMessage);
          // }
        });
      } else {
        this.editCategoryCode = -1;
      }
    },
    //删除
    del(categoryCode) {
      let that = this;
      Modal.confirm({
        title: "提示",
        icon: () => createVNode(ExclamationCircleOutlined),
        content:
          "是否确认删除该渠道？",
        cancelText: "取消",
        okText: "确认",
        onOk() {
          channelDelete(categoryCode).then((res: any) => {
            if (res.data.success) {
              message.success("删除渠道成功");
              (that as any).$store.commit(
                "changeChannelCode1",
                (that as any).$store.state.channel1
              );
              that.$emit("myEvent"); //通知第一栏重新获取数据
              (that as any).$store.commit("changeChannelCode2", "z"); //控制第三栏不显示
              (that as any).$store.state.channelCode2 = []; //控制第三栏不显示
              that.editCategoryCode = -1;
            }
            //  else {
            //   message.error(res.data.errMessage);
            // }
          });
        },
      });
    },
    //发送排序的请求
    sortList(e) {
      setTimeout(() => {
        (this as any).$store.commit("changeloadingStatus", false);
      }, 0);
      return channelOrder({
        channelCode: e.item._underlying_vm_.channelCode,
        afterSort: e.newIndex,
      });
    },
    //拖拽结束触发promiseList确保顺序发送请求
    dragEnd(e) {
      // console.log(e.item._underlying_vm_);
      // console.log(e);
      if ((this as any).$store.state.channelPromiseList2.length === 0) {
        let P: any;
        P = new Promise((resolve, reject) => {
          this.sortList(e).then(() => {
            (this as any).$store.commit("channelPromiseList2_shift");
            resolve("");
          });
        });
        (this as any).$store.commit("channelPromiseList2_push", P);
      } else {
        let P: any;
        P = new Promise((resolve, reject) => {
          (this as any).$store.state.channelPromiseList2[
            (this as any).$store.state.channelPromiseList2.length - 1
          ].then(() => {
            this.sortList(e).then(() => {
              (this as any).$store.commit("channelPromiseList2_shift");
              resolve("");
            });
          });
        });
        (this as any).$store.commit("channelPromiseList2_push", P);
      }
    },
    //更改排序
    // handleSort() {
    //   this.loading = true;
    //   let array: any = [];
    //   (this as any).$store.state.gradeCode1.forEach((el: any, i) => {
    //     el.sort = 1000 - i;
    //     array = [...array, { categoryCode: el.categoryCode, sort: el.sort }];
    //   });
    //   sortCategory({
    //     categoryParCode: (this as any).$store.state.grade1,
    //     sortCategory: array,
    //   }).then((res) => {
    //     if (res.data.success) {
    //       (this as any).$store.commit(
    //         "changeGradeCode1",
    //         (this as any).$store.state.grade1
    //       );
    //       message.success("排序成功");
    //       this.drag = false;
    //     }
    //     // else {
    //     //   message.error("服务器开了个小差");
    //     // }
    //     this.loading = false;
    //   });
    // },
    //点击
    active(code) {
      this.activeCode = code;
      (this as any).$store.commit("changeChannelCode2", code);
      //(this as any).$store.state.gradeCode4 = [];
      (this as any).$store.state.channelCode3 = [];
      (this as any).$store.commit("changeChannelCode3", "z");
      //(this as any).$store.commit("changeGradeCode4", "z");
    },
  },
  watch: {
    editCategoryCode(value) {
      if (value != -1) {
        (this as any).$store.commit("changeCategoryEdit", true);
      } else {
        (this as any).$store.commit("changeCategoryEdit", false);
      }
    },
  },
  created() {
    this.height = document.body.clientHeight - 160 + "px";
    this.contentHeight = document.body.clientHeight - 238 + "px";
  },
  updated() {
    this.activeCode = "";
  },
  beforeUnmount() {
    this.newCategory = "";
  },
});
